/* eslint-disable prettier/prettier */
import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Tooltip,
  IconButton,
  Modal,
  CircularProgress,
} from '@material-ui/core';
import { Block, DoneOutline, DeleteForever, Refresh } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { format, isValid } from 'date-fns';
import MaterialTable from 'components/materialtable/MaterialTable';
import CompanyService from 'services/CompanyService';
import TreezorService from 'services/treezorservice';
import { LOCK_CARD } from 'Config';
import queryStringHelper from 'utils/queryStringHelper';
import state from './state';
import dispatch from './dispatch';

import styles from '../../assets/jss/root';
import CustomDialog from '../../components/CustomDialog';

export class CompanyList extends Component {
  // history = useHistory();
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      filterValue: '',
      showConfirmDialog: false,
      selectedCompany: {},
      showConfirmDelete: false,
      showResultDialog: false,
    };
    this._formatDate = this._formatDate.bind(this);
    this._filterList = this._filterList.bind(this);
    this._toggleSuspend = this._toggleSuspend.bind(this);
    this._toggleDialog = this._toggleDialog.bind(this);
    this._renderConfirmationDialogs = this._renderConfirmationDialogs.bind(
      this
    );
    this._selectCompany = this._selectCompany.bind(this);
    this._handleClick = this._handleClick.bind(this);
    this.tableRef = React.createRef();
    this._fetchCards = this._fetchCards.bind(this);
    this._updateCardStatus = this._updateCardStatus.bind(this);
  }

  // this.delete()

  // eslint-disable-next-line class-methods-use-this
  _formatDate(dateString) {
    const date = new Date(dateString);
    if (isValid(date)) {
      const formattedDate = format(date, 'yyyy-MM-dd');
      return formattedDate;
    }
    return '-';
  }

  _filterList() {
    const { filterValue } = this.state;
    const { companies } = this.props;
    if (filterValue) {
      const iFilter = filterValue.toLowerCase();
      const filteredCompanies = companies.filter(
        (comp) => comp.name && comp.name.toLowerCase().includes(iFilter)
      );

      return filteredCompanies;
    }
    return companies;
  }

  _onFilterChange(e) {
    this.setState({ filterValue: e.target.value });
    this._filterList();
  }

  _toggleDialog() {
    this.setState({
      showConfirmDialog: !this.state.showConfirmDialog,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  async _toggleSuspend(company) {
    const { updateCompany } = this.props;
    const shouldBeDisabled = !company.suspended;

    if (shouldBeDisabled && company.treezorUser) {
      const cards = await this._fetchCards(company._id);

      if (Array.isArray(cards)) {
        const cardPromises = cards.map(async (card) => {
          await this._updateCardStatus(company._id, card._id, LOCK_CARD);
        });

        await Promise.all(cardPromises);
      }
      updateCompany(company);
      return this._toggleDialog();
    }

    updateCompany(company);
    return this._toggleDialog();
  }

  // eslint-disable-next-line class-methods-use-this
  async _fetchCards(companyId) {
    try {
      const resultCards = await TreezorService.fetchCards(companyId);
      return resultCards.data;
    } catch (error) {
      // setMessage(error);
      return error;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async _updateCardStatus(companyId, cardOId, status) {
    const data = {
      lockStatus: status,
    };

    try {
      const result = await TreezorService.lockUnlockCard(
        companyId,
        cardOId,
        data
      );
      return result.data;
    } catch (error) {
      // setMessage(e.message);
      return error;
    }
  }

  _selectCompany(comp) {
    this.setState({
      selectedCompany: comp,
    });
  }

  _handleClick(comp) {
    this._selectCompany(comp);
    this._toggleDialog();
  }

  componentDidMount() {
    this.props.setAppTitle('Company List');
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isDeletingCompany !== this.props.isDeletingCompany &&
      prevProps.isDeletingCompany &&
      !this.props.errorDeletingCompany
    ) {
      this.setState({ showResultDialog: true });
    }
  }

  _renderConfirmationDialogs() {
    const {
      selectedCompany,
      showConfirmDialog,
      showResultDialog,
      showConfirmDelete,
    } = this.state;
    return (
      <Fragment>
        <CustomDialog
          open={showConfirmDialog}
          title="Confirm Company Status Change for"
          contentText={selectedCompany.name}
          showButtons
          confirmText="ok"
          onConfirm={() => this._toggleSuspend(selectedCompany)}
          cancelText="cancel"
          onCancel={() => this._toggleDialog()}
        />
        <CustomDialog
          open={showConfirmDelete}
          title="Are you sure you want to delete this company:"
          contentText={selectedCompany.name}
          showButtons
          confirmText="ok"
          onConfirm={() => {
            this.props.deleteCompany(selectedCompany._id);
            this.setState({ showConfirmDelete: false });
          }}
          cancelText="cancel"
          onCancel={() => this.setState({ showConfirmDelete: false })}
        />
        <CustomDialog
          userLoggedIn
          open={showResultDialog}
          title="Company Deletion results"
          contentText={
            this.props.companyDeleteResult
              ? this.props.companyDeleteResult.join(', ')
              : ''
          }
          closeDialog={() => {
            this.setState({ showResultDialog: false });
          }}
        />
      </Fragment>
    );
  }

  _renderTable() {
    return (
      <MaterialTable
        title=""
        tableRef={this.tableRef}
        columns={[
          {
            title: 'id',
            field: '_id',
            render: (rowData) => {
              if (rowData.creation) {
                return (
                  <Link to={`/creationcompany/${rowData._id}`}>
                    {rowData._id}
                  </Link>
                );
              }

              return (
                <Link to={`/companydetails/${rowData._id}`}>{rowData._id}</Link>
              );
            },
          },
          { title: 'Company name', field: 'name' },
          { title: 'Business name', field: 'business_name' },
          {
            title: 'Siret',
            field: 'siren',
            render: (company) => <>{`${company.siren}${company.nic}`}</>,
          },
          { title: 'Adress', field: 'address' },
          {
            title: 'Signup Date',
            type: 'date',
            field: 'created_at',
            render: (company) => {
              return (
                <>{this._formatDate(company.created_at || company.createdAt)}</>
              );
            },
          },
          {
            title: 'Is active',
            field: 'isActive',
            type: 'boolean',
            render: (company) => {
              return (
                <Tooltip title="Change comapny status">
                  <span>
                    <IconButton
                      onClick={() => this._handleClick(company)}
                      disabled
                    >
                      {company.suspended ? <Block /> : <DoneOutline />}
                    </IconButton>
                  </span>
                </Tooltip>
              );
            },
          },
          {
            title: '',
            render: (company) => {
              return (
                <Tooltip title="Delete company">
                  <IconButton
                    onClick={() =>
                      this.setState({
                        showConfirmDelete: true,
                        selectedCompany: company,
                      })
                    }
                  >
                    <DeleteForever />
                  </IconButton>
                </Tooltip>
              );
            },
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            CompanyService.fetchCompanies(queryStringHelper(query))
              .then((res) => {
                resolve({
                  data: res.data.docs,
                  page: res.data.page - 1,
                  totalCount: res.data.totalDocs,
                });
              })
              .catch((err) => {
                reject(err);
              });
          })
        }
        actions={[
          {
            icon: () => <Refresh />,
            tooltip: 'Refresh Data',
            isFreeAction: true,
            onClick: () =>
              this.tableRef.current && this.tableRef.current.onQueryChange(),
          },
        ]}
        options={{
          search: true,
          filtering: true,
        }}
      />
    );
  }

  render() {
    const {
      errorDeletingCompany,
      isDeletingCompany,
      isFetchingCompanies,
      errorFetchingCompanies,
    } = this.props;
    return (
      <div style={{ width: '100%' }}>
        {this._renderConfirmationDialogs()}
        <Modal
          open={isDeletingCompany || isFetchingCompanies}
          disableBackdropClick={true}
        >
          <CircularProgress
            style={{
              outline: 'none',
              position: 'absolute',
              left: '50%',
              top: '50%',
            }}
          />
        </Modal>
        <div>
          {errorDeletingCompany ? (
            <div style={{ color: 'red' }}>
              Error deleting company, please try again
            </div>
          ) : null}
          {errorFetchingCompanies ? (
            <div style={{ color: 'red' }}>
              Error fetching companies, please try again
            </div>
          ) : null}
        </div>
        <div>{this._renderTable()}</div>
      </div>
    );
  }
}

CompanyList.propTypes = {
  // selectCompany: PropTypes.func,
  companyDeleteResult: PropTypes.array,
  classes: PropTypes.object.isRequired,
  companies: PropTypes.array.isRequired,
  updateCompany: PropTypes.func.isRequired,
  isDeletingCompany: PropTypes.bool.isRequired,
  errorDeletingCompany: PropTypes.bool.isRequired,
  deleteCompany: PropTypes.func.isRequired,
  isFetchingCompanies: PropTypes.bool.isRequired,
  errorFetchingCompanies: PropTypes.bool.isRequired,
  fetchAllCompanies: PropTypes.func.isRequired,
  history: PropTypes.object,
  setAppTitle: PropTypes.func.isRequired,
};

const CompanyListWithStyles = withStyles(styles, { withTheme: true })(
  CompanyList
);

export default connect(state, dispatch)(CompanyListWithStyles);
